import React from 'react'

const NavList = ({ ul_class, li_class, close_click }) => {
    return (
        <ul className={`${ul_class}`}>
            <li className={`${li_class}`}><a href="/" onClick={close_click ? close_click : close_click}>Home</a></li>
            <li className={`${li_class}`}><a href="#about" onClick={close_click ? close_click : close_click}>About</a></li>
            <li className={`${li_class}`}><a href="#tarrif" onClick={close_click ? close_click : close_click}>Tarrif</a></li>
            <li className={`${li_class}`}><a href="#cities" onClick={close_click ? close_click : close_click}>Drop Taxi Cities</a></li>
            <li className={`${li_class}`}><a href="#contact" onClick={close_click ? close_click : close_click}>Contact</a></li>
            <li ><a href="#book_taxi_form" className='px-4 py-2 text-white rounded-3xl th-btn-book header-call-btn'  onClick={close_click ? close_click : close_click}>Book Now</a></li>
        </ul>
    )
}

export default NavList