import React, { useState } from "react";
import OneWay from "./OneWay";
import Roundtrip from "./Roundtrip";
import { useForm } from "react-hook-form";
import indiaFlag from "../assets/images/indiaflag.png";
import axios from "axios";
import {
  telegramApi,
  telegramChatId,
  netbixTelegramApi,
  netbixTelegramChatId,
} from "../config/config";

const Form = () => {

  const [fromChange, setFormChange] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState("");
  function changeFroms(e) {
    if (e.target.value == "oneway") {
      setFormChange(false);
    } else {
      setFormChange(true);
    }
  }
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  // const onSubmit = (data) => {
  //   try {
  //     const response = axios.post(
  //       `https://api.telegram.org/bot${telegramApi}/sendMessage`,
  //       {
  //         chat_id: telegramChatId,
  //         text: "Customer Mobile number : +91 " + data.mob_number,
  //       }
  //     );
  //     if (response) {
  //       setMobileNumber(data.mob_number);
  //     }
  //   } catch (error) {
  //     alert("Please try again");
  //     console.error("Error sending Telegram message:", error);
  //     // Handle the error
  //   }
  //   try {
  //     axios.post(
  //       `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
  //       {
  //         chat_id: netbixTelegramChatId,
  //         text: "Customer Mobile number : +91 " + data.mob_number,
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Error sending Telegram message:", error);
  //     // Handle the error
  //   }
  // };
  // register("mob_number", {
  //   required: true,
  //   validate: (value) =>
  //     !isNaN(value) ? undefined : "Please enter a valid number",
  // });
  return (
    <div className="">
      <div className="container mx-auto ">
        <h3 className=" mt-4 font-bold text-[30px] sm:text-[40px] md:text-[50px] text-center">
          Book One Way Taxi{ " "}
        </h3>
        <p className="sm:mt-2 md:mt-4 text-center text-sm ">
          {" "}
          We are providing one way taxi, outstation cabs, drop taxi and airport
          taxi service.
        </p>
        {/* <div className="pt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 justify-items-center py-3 pb-5 bg-white rounded-md items-center">
            <label
              className={`font-semibold hover:text-[#000] py-2 ${
                fromChange ? "" : "text-white form_shadow"
              } cursor-pointer`}
              htmlFor="oneway"
              onClick={changeFroms}
            >
              One Way
              <input
                type="radio"
                name="type"
                id="oneway"
                value="oneway"
                className="w-0 h-0"
              />
            </label>
            <label
              className={`font-semibold hover:text-[#000]  py-2 cursor-pointer  ${
                fromChange ? " form_shadow" : ""
              }`}
              htmlFor="round"
              onClick={changeFroms}
            >
              Round Trip
              <input
                type="radio"
                name="type"
                id="round"
                value="round"
                className="w-0 h-0"
              />
            </label>
          </div>
        </div> */}
        {/* {mobileNumber === "" && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="p-3">
                <div className=" relative">
                  <span
                    className={`top-1/2 left-0 h-full px-2 border text-[16px] absolute -translate-y-1/2 flex gap-x-1 items-center ${
                      errors.mob_number && "border border-red-500"
                    }`}
                  >
                    <span>
                      <img src={indiaFlag} className="w-6" />
                    </span>
                    <span className=" text-white">+91</span>
                  </span>
                  <input
                    type="text"
                    className={`w-full border  py-3 px-4 focus:outline-0 pl-20 ${
                      errors.mob_number && "border border-red-500"
                    }`}
                    {...register("mob_number", {
                      required: true,
                      validate: (value) => {
                        if (!isNaN(value)) {
                          return value.length === 10
                            ? undefined
                            : "Mobile number should be 10 digits";
                        } else {
                          return "Please enter a valid number";
                        }
                      },
                    })}
                    placeholder="Mobile number"
                    name="mob_number"
                  />
                </div>
                {errors.mob_number !== undefined && errors.mob_number.type === "validate" && <p className=" bg-red-700 text-white py-[1px] px-[5px] text-sm flex items-center rounded-sm mt-1">{errors.mob_number.message}</p>}
                
              </div>
            </div>
            <div className="text-center mt-3">
              <button className="mt-4py-3 px-6 th-btn">GET TAXI</button>
            </div>
          </form>
        )} */}

        { (
          !fromChange ? (
            <OneWay
              tripType={setFormChange}
              fromChange={fromChange}
                          />
          ) : (
            <Roundtrip
              tripType={setFormChange}
              fromChange={fromChange}
            />
          )
        ) }
      </div>
    </div>
  );
};

export default Form;
