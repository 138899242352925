import React from 'react'
import about from "../assets/images/superfastcabs.jpg";
const About = () => {
    return (
        <div  id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-14 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[400px] car_outline sm:w-[300px] h-[300px]' />
                            {/* <div className=' absolute bottom-2 sm: bottom-[-70px] right-[110px] md:bottom-[-60px] right-[1] translate-x-1/2 md:translate-x-0 md:right-[60px]  bg-white py-4 px-4 rounded-xl shadow-md'>
                                <div className=' flex flex-col gap-y-1 items-center'>
                                    <span className='primary-color text-4xl font-bold'>10+</span>
                                    <span className=' text-lg opacity-60 font-medium'>Years Experience</span>
                                </div>

                            </div> */}
                        </div>
                    </div>
                    <div className='mt-20 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium text-black bold'>About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4'>Superfast One Way Taxi Service in Tamil Nadu
</div>
                        <p className='text-black opacity-60 md:text-lg mt-5'>Welcome to Superfast One Way Cabs, your reliable companion for hassle-free travel across Tamil Nadu. Our commitment is to provide you with seamless, convenient, and efficient one-way taxi services that cater to all your travel needs. Whether you're planning a solo trip, family vacation, or a business venture, we ensure a comfortable journey tailored to your preferences.
</p>                     
                                        <div className=' mt-5'>
                        <a  href="tel:+916382011120"  className='th-btn'>Contact for More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About