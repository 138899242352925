import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium text-blue font-bold text-center">
          Here's why choosing Superfast One Way Cabs is your best travel decision:        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4">
          <h3 className="font-semibold text-3xl  sm:text-2xl">One-Way Trips:</h3>
          <p>
            We specialize in one-way taxi services, allowing you to travel from one destination to another without worrying about the return journey. Whether it's a city-to-city transfer or an inter-city trip, we've got you covered.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Swift and Reliable Service:</h3>
          <p>
          With our fleet of well-maintained vehicles and experienced drivers, we guarantee punctuality and reliability. Sit back, relax, and enjoy a smooth ride to your destination while we take care of the rest.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Transparent Pricing:</h3>
          <p>
          Say goodbye to hidden charges and unexpected costs. Our transparent pricing policy ensures that you know exactly what you're paying for upfront, with no surprises along the way.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Wide Coverage:</h3>
          <p>
          From bustling urban centers to serene countryside landscapes, our service covers a wide range of destinations across Tamil Nadu. Wherever your journey takes you, we'll be there to facilitate a comfortable and enjoyable ride.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">24/7 Availability:</h3>
          <p>
          Whether it's an early morning departure or a late-night arrival, our round-the-clock service ensures that you can book a ride whenever you need it. Simply schedule your trip, and we'll be at your service, ready to assist you at any hour of the day.              </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Customer Satisfaction:</h3>
          <p>
          Your satisfaction is our top priority. We go the extra mile to ensure that every aspect of your journey meets and exceeds your expectations. From clean and well-maintained vehicles to courteous and professional drivers, we strive to deliver a superior travel experience.           </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">24/7 Availability:</h3>
          <p>
          Booking your ride with Superfast One Way Cabs is quick, easy, and convenient. Simply visit our website, enter your travel details, choose your vehicle, and make your reservation in just a few clicks. You can also reach out to our customer support team for assistance or special requests.           </p>
        </div>
        <p className="mt-4">
        Experience the convenience and comfort of traveling with Superfast One Way Cabs on your next trip across Tamil Nadu. Book your ride today and embark on a journey that's fast, reliable, and hassle-free.        </p>



      </div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium text-blue text-center">
          Our Service
        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Drop Taxi Services:</h3>
          <p>
            Whether you need a ride across town or to a neighboring city, our drop taxi services offer a convenient and cost-effective solution for your travel needs.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
          <p>
            Start or end your journey hassle-free with our reliable airport transfer services. We'll ensure you reach your destination on time, relaxed, and ready for your flight.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Outstation Trips:</h3>
          <p>
            Explore the beauty of Tamil Nadu and beyond with our customizable outstation trip packages. Sit back, relax, and let us take you on an unforgettable journey.
            Book Your Ride Today!
          </p>
        </div>
        <p className="mt-4">
          Experience the ultimate in convenience, reliability, and affordability with Super Fast Oneway Cabs. Book your ride today and discover why we're the preferred choice for drop taxi services in Tamil Nadu. Get in touch with us now to plan your next journey!
        </p>
      </div>
      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Variety of Prime Brands
            </p>
            <p>Benz - BMW - Audi</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
