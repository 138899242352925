import React from "react";
import bg from "../../assets/images/servbg.png";
import chennai from "../../assets/images/services/chennai.jpg";
import coimbatore from "../../assets/images/services/coimbatore.jpg";
import bangalore from "../../assets/images/services/bangalore.jpg";
import madurai from "../../assets/images/services/madurai.jpeg";
import pondicherry from "../../assets/images/services/pondi.jpg";
import trichy from "../../assets/images/services/trichy.jpeg";
import vellore from "../../assets/images/services/vellore.jpg";
import kumbakonam from "../../assets/images/services/kumbakonam.webp";
import salem from "../../assets/images/services/salem.jpg";
import OneWayServicesComponent from "./OneWayServicesComponent";
const OneWayServices = () => {
  return (
    <div className="serv_parent" style={{backgroundImage: `url(${bg})`}}>
      <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 sm:px-4 md:px-8 lg:px-16 text-white" id="cities">
        <h4 className="text-[#241D92] text-lg font-medium primary-color">
        One Way Taxi Service
        </h4>
        <h3 className="mt-4 font-bold text-3xl md:text-4xl">
        Popular Drop Taxi Routes
        </h3>
        <p className=" mt-4">Super Fast Oneway Cabs 
 stands out from other service providers by offering competitive prices without compromising on quality or customer satisfaction. Our commitment to providing affordable transportation solutions allows travelers to experience these popular routes at a lower cost, making their journey enjoyable and pocket-friendly.</p>
        <div className=" mt-5 md:mt-7 lg:mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3">
           <OneWayServicesComponent place="CHENNAI" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={chennai} />
           <OneWayServicesComponent place="COIMBATORE" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={coimbatore} />
           <OneWayServicesComponent place="BANGALORE" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={bangalore} />
           <OneWayServicesComponent place="SALEM" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={salem} />
           <OneWayServicesComponent place="MADURAI" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={madurai} />
           <OneWayServicesComponent place="PONDICHERRY" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={pondicherry} />
           <OneWayServicesComponent place="VELLORE" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={vellore} />
           <OneWayServicesComponent place="TRICHY" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={trichy} />
           <OneWayServicesComponent place="KUMBAKONAM" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Super Fast Oneway Cabs 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Super Fast Oneway Cabs 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={kumbakonam} />
        </div>
      </div>

    </div>
  );
};

export default OneWayServices;
